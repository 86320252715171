<template>
  <v-row>
    <v-col>
      <div class="d-flex flex-column" style="gap: 10px;">
        <div v-if="css != null" class="css">
          <v-chip label x-small pill>css</v-chip>
          <prism-editor v-model="css" :highlight="$highlighter('css')" />
        </div>
          <v-chip v-else-if="isAdmin" label x-small pill @click="css = ''">css</v-chip>
        <div v-if="js != null" class="js">
            <v-chip label x-small pill>js</v-chip>
          <prism-editor v-model="js" :highlight="$highlighter('javascript')" />
        </div>
          <v-chip v-else-if="isAdmin" label x-small pill @click="js = ''">js</v-chip>
        <div v-if="html != null" class="html">
          <v-chip label x-small pill>html</v-chip>
          <prism-editor v-model="html" :highlight="$highlighter('markup')" />
<!--            <div class="d-flex flex-wrap " style="gap: 5px;">-->
<!--                <div-->
<!--                    v-for="tag in tagList" :key="tag"-->
<!--                    class="tag"-->
<!--                >-->
<!--                    <v-chip x-small>-->
<!--                        {{tag}}-->
<!--                    </v-chip>-->
<!--                    <div class="desc">-->
<!--                        <p>Тег: {{tag}}</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
          <v-chip v-else-if="isAdmin" label x-small pill @click="html = ''">html</v-chip>
<!--          <div>-->
<!--            <span v-for="node in htmlStructure.childNodes">-->
<!--                <HTMLTag :node="node"/>-->
<!--            </span>-->
<!--          </div>-->
      </div>
    </v-col>
    <v-col class="flex-column justify-items-stretch" :class="{'d-flex': html, 'd-none': !html}" v-if="(html || js) && !hidePreview">
        <div v-if="htmlTitle"  class="tabTitle">
            {{htmlTitle}}
            <v-icon>mdi-close</v-icon>
        </div>
      <iframe style="width: 100%;flex-grow: 1;min-height: 350px;" :srcdoc="iframe" rel="iframe" />
    </v-col>
  </v-row>
</template>

<script>
import { parse } from 'node-html-parser';
import HTMLTag from "@/components/HTMLTag.vue";
export default {
  name: "WebCode",
    components: {HTMLTag},
  props: {code: Object, hidePreview: {type: Boolean, default: false}},
  data: () => ({
    html: null,
    css: null,
    js: null
  }),
  computed: {
    htmlDom(){
        let doc = new DOMParser().parseFromString(this.html  ?? '', 'text/html');
        doc.head.innerHTML += '<style>' + this.css + '</style>'
        doc.body.innerHTML += '<script>' + this.js + '<$$$script>'.replace('$$$', '/')
        return doc
        //   let str = '<head><style>' + this.css + '</style></head>'
      //
      // str += '<body>' + this.html
      // str += '<script>' + this.js + '<$$$script>'.replace('$$$', '/') + '</body>'
      // return str
    },
      htmlTitle(){
        return this.htmlDom.head.querySelector('title')?.textContent ?? ''
      },
      iframe(){
        return this.htmlDom.documentElement.innerHTML
      },
      tagList(){
          let match = this.html.match(/<([^>]+)>/g)
          let tags = []
          for(let item of match){
              let tag = item.replace(/[<>\/]/g, '').split(' ')[0]
              if(!tags.includes(tag)){
                  tags.push(tag)
              }
          }
          return tags
      },
    newCode(){
      return {
        html: this.html,
        css: this.css,
        js: this.js
      }
    },
    htmlStructure(){
        return parse(this.html)
    },

      isAdmin(){
          return this.$page?.props?.isAdmin
      }
  },
  watch: {
    newCode(){
        console.clear()
      // console.log('upd')
      this.$emit('update:code', this.newCode)
    }
  },
  mounted() {
    this.html = this.code.html ?? null
    this.css = this.code.css ?? null
    this.js = this.code.js ?? null
  }
}
</script>

<style lang="scss">
.html, .css, .js{
  border: 1px solid black;
  padding: 15px 10px 10px 10px;
    position: relative;
    .v-chip{
        position: absolute;
        top: 0;
        left: 0;
        border-top-left-radius: 0!important;
    }
    textarea{
        outline: none;
    }
}
.tabTitle{
    border-radius: 10px 10px 0 0;
    border: 1px solid #cccccc;
    border-bottom: none;
    width: fit-content;
    padding: 5px 10px;
}
.tag{
    position: relative;
}
.tag:hover .desc{
    display: block;
}
.tag .desc{
    display: none;
    position: absolute;
    bottom: calc(100% + 5px);
    background: white;
    /*height: 100px;*/
    min-width: 100px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px;
}
</style>
