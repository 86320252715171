<template>
    <g-layout :title="type.title">
        <v-row style="position: relative;">
            <v-col style="max-width: 150px;overflow-y: auto;max-height: calc(100vh - 85px);position: sticky;top:100px;">
                <v-list class="js-slides-list">
                    <v-list-item link v-for="(item, index) in items" :key="item.id"
                                 @click="active = index"  class="js-slides-list__item">
                        <v-list-item-content>
                            <v-list-item-title>{{item.title}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="isAdmin"
                                 @click="add"  class="js-slides-list__item">
                        <v-list-item-content>
                            <v-list-item-title>+</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <!--      <v-alert-->
                <!--          color="primary" dark-->
                <!--          v-for="item in csv"-->
                <!--          :outlined="item.prop !== prop"-->
                <!--          @click="prop = item.prop"-->
                <!--          style="cursor: pointer"-->
                <!--      >-->
                <!--        <div style="font-size: 50%;">{{item.type}}</div>-->
                <!--        <div style="font-size: 75%;">{{item.title}}</div>-->
                <!--        {{item.prop}}-->
                <!--      </v-alert>-->
            </v-col>
            <v-col style="overflow-y: auto;max-height: calc(100vh - 85px);">
                <template v-if="item">
                    <v-text-field v-if="isAdmin" v-model="item.title" />
                    <h2 v-html="item.title" />
                    <web-code :code="item" :key="item.id" ref="code"/>
                    <template v-if="isAdmin">
                        <v-btn @click="save">Сохранить</v-btn>
                        <v-btn @click="add">Добавить слайд</v-btn>
                    </template>
                </template>
            </v-col>
        </v-row>
    </g-layout>
</template>

<script>
import axios from "axios";
import {Inertia} from "@inertiajs/inertia";
export default {
    name: "JSSlides",
    props: {
        type: {},
        items: {}
    },
    data: () => ({
        active: 0,
        // title: ''
    }),
    computed: {
        item(){
            return this.items[this.active] ?? null
        },
        isAdmin(){
            return this.$page?.props?.isAdmin
        }
    },
    watch: {
        // item(){
        //     console.clear()
        //     console.log(`Слайд: "${this.item.title}"`)
        // }
    },
    methods: {
        async save(){
            let data = {
                title: this.item.title,
                css: this.$refs.code.css,
                js: this.$refs.code.js,
                html: this.$refs.code.html
            }
            await axios.post('/js/slides/'+this.item.id, data)
            Inertia.reload()
        },
        async add(){
            let data = {
                title: 'Название',
                js: '// code',
                type: this.type.id,
            }
            if(this.type.id > 1){
                data.html = '<!-- code -->'
            }
            await axios.post('/js/slides', data)
            Inertia.reload()
        }
    }
}
</script>

<style>
html, body{
    overflow: hidden;
}
.js-slides-list .v-list-item__title{
    font-size: 12px;
    white-space: break-spaces;
}
.js-slides-list__item{
    /*padding-left: 30px;*/
    border: 1px solid #1976d2;
    /*margin-left: 20px;*/
    margin-top: 10px;
    border-radius: 10px;
}
</style>
