<template>
    <g-layout>

        <jet-validation-errors class="mb-4" />
        <v-form @submit.prevent="submit">
            <v-card class="pa-6 ma-auto" max-width="600">
                <v-card-title>Регистрация</v-card-title>
                <v-card-text>
                    <v-text-field label="Имя" id="name" type="text" class="mt-1 block w-full" v-model="form.name" required autofocus autocomplete="name" />
                    <v-text-field label="Эл. почта" id="email" type="email" class="mt-1 block w-full" v-model="form.email" required />
                    <v-text-field label="Пароль" id="password" type="password" class="mt-1 block w-full" v-model="form.password" required autocomplete="new-password" />
                    <v-text-field label="Подтверждение пароля" id="password_confirmation" type="password" class="mt-1 block w-full" v-model="form.password_confirmation" required autocomplete="new-password" />


                    <div class="flex items-center justify-end mt-4">
                        <inertia-link :href="route('login')" class="underline text-sm text-gray-600 hover:text-gray-900">
                            Already registered?
                        </inertia-link>

                        <v-btn type="submit" class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Register
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-form>
    </g-layout>
</template>

<script>
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard'
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo'
    import JetButton from '@/Jetstream/Button'
    import JetInput from '@/Jetstream/Input'
    import JetCheckbox from "@/Jetstream/Checkbox";
    import JetLabel from '@/Jetstream/Label'
    import JetValidationErrors from '@/Jetstream/ValidationErrors'

    export default {
        components: {
            JetAuthenticationCard,
            JetAuthenticationCardLogo,
            JetButton,
            JetInput,
            JetCheckbox,
            JetLabel,
            JetValidationErrors
        },

        data() {
            return {
                form: this.$inertia.form({
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    terms: false,
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('register'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            }
        }
    }
</script>
